import type * as pt from "@prismicio/types"
import { CmsGuideLayout } from "../../../components/CmsGuideLayout"
import { lorem } from "../../../lib/lorem"
import { PageDataBodyForm } from "../../../slices/PageDataBodyForm"

const Component = () => {
	return (
		<CmsGuideLayout>
			<PageDataBodyForm
				formUid="fake-form-uid"
				text={
					[
						{ type: "paragraph", text: lorem.sentences(2), spans: [] },
					] as pt.RichTextField
				}
				fields={[
					{
						__typename: "PrismicFormDataBodyTextField",
						primary: {
							label: { text: "First Name" },
							required: true,
							full_width: false,
						},
					},
					{
						__typename: "PrismicFormDataBodyTextField",
						primary: {
							label: { text: "Last Name" },
							required: true,
							full_width: false,
						},
					},
					{
						__typename: "PrismicFormDataBodyTextField",
						primary: {
							label: { text: "Phone" },
							required: true,
							full_width: false,
						},
					},
					{
						__typename: "PrismicFormDataBodyTextField",
						primary: {
							label: { text: "Email" },
							required: true,
							full_width: false,
						},
					},
					{
						__typename: "PrismicFormDataBodyTextareaField",
						primary: { label: { text: "Message" }, required: true },
					},
				]}
				successHeading={lorem.words(4)}
				successText={lorem.sentences(1)}
			/>
		</CmsGuideLayout>
	)
}

export default Component
